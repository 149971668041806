
.hover_content {
  font-size: 12px;
  color: #fff;
  opacity: 0;
  max-height: 0;
  transition: 0.4s ease;
}

.banner-box:hover .hover_content {
  opacity: 1;
  max-height: 150px;
}
.banner-box:hover .banner-text {
  bottom: 25%;
}


.category1 {
  padding: 5px;
  font-size: 10px;
  max-width: fit-content;
  background-color: crimson;
  color: #fff;
}
.category2 {
  padding: 5px;
  font-size: 10px;
  max-width: fit-content;
  background-color: #581da1;
  color: #fff;
}
.category3 {
  padding: 5px;
  font-size: 10px;
  max-width: fit-content;
  background-color: #0b9931;
  color: #fff;
}
.category4 {
  padding: 5px;
  font-size: 10px;
  max-width: fit-content;
  background-color: #c78823;
  color: #fff;
}


@media screen and (max-width: 768px) {

  .banner-box:hover .hover_content {
    opacity: 0;
    max-height: 0px;
  }
}