@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* body {
  font-family: 'Work Sans', sans-serif;
} */

.work-sans {
  font-family: Work sans !important;
}

.oswald {
  font-family: Oswald !important;
}



.h1 {
  font-family: Oswald !important;
  font-size: 32px !important;

}
.h2 {
  font-family: Oswald !important;
  font-size: 24px !important;

}

.h3 {
  font-family: Oswald !important;
  font-size: 1.125rem !important;

}

.text1 {
  font-family: Oswald !important;
  font-size: 18px !important;
}

.text2 {
  font-family: 'Work Sans' !important;
  font-size: 16px !important;
}

.text3 {
  font-family: 'Work Sans' !important;
  font-size: 14px !important;
}

.subText1 {
  font-family: 'Work Sans' !important;
  font-size: 12px !important;
}

.subText2 {
  font-family: 'Work Sans' !important;
  font-size: 10px !important;
}

.subText3 {
  font-family: 'Work Sans' !important;
  font-size: 8px !important;
}

.text-white {
  color: #fff !important;
}

.text-pink {
  color: #F1459D !important;
}

.text-red {
  color: #CC0000 !important;
}

.text-purple {
  color: #4F11AD !important;
}

.text-orange {
  color: #FE4A51 !important;
}

.text-black {
  color: '#000' !important
}

.primary-pink {
  background-color: #F1459D !important;
}

.primary-red {
  background-color: #CC0000 !important;
}

.primary-gray {
  background-color: #454545 !important;
}


.primary-purple {
  background-color: #4F11AD !important;
}

.primary-orange {
  background-color: #FE4A51 !important;
}

.primary-white {
  background-color: #fff !important;
}

.opacity1 {
  opacity: 0.8 !important;
}

.opacity2 {
  opacity: 0.5 !important;
}

.opacity3 {
  opacity: 0.3 !important;
}

.main-button {
  background-color: #CC0000 !important;
  color: #fff !important;
  box-shadow: 0px 2px 6px 0px #00000040 !important;
  border: none !important;
  border-radius: 0px !important;
  width: '100%' !important;
}

.input-feild {
  background-color: #fff !important;
  color: #000 !important;
  border: none !important;
  font-family: Oswald !important;
  font-weight: 200 !important;
  padding: 2px 8px !important;
  width: 100% !important;

}

.input-field:active {
  outline: none !important;
}

.center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.image {
  object-fit: cover !important;
}

.cursor {
  cursor: pointer;
}

i {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #393939;
}


ul {
  list-style-type: none;
}

.bold {
  font-weight: 700 !important;
}

.sub-bold {
  font-weight: 500 !important;
}

.mid {
  font-weight: 400 !important;
}

.sub-light {
  font-weight: 300 !important;
}

.light {
  font-weight: 200 !important;
}

.active {
  color: #CC0000 !important;
}

a.link:hover {
  color: #393939;
}

.news-title {
  font-family: Oswald !important;
  font-size: 15px !important;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

}

.authorDate {
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;


}

.card-author {
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;

}

.mainCard-heading {
  font-family: Oswald !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;

}

.mainCard-text {
  font-size: 14px;
  font-weight: 200;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;

}


.purchaseNowCard {
  background-image: url(../src/Images//purchase-now.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}


.video-heading {
  font-family: Oswald !important;
  font-size: 36px !important;
  font-weight: 500 !important;
  line-height: 60px !important;
  letter-spacing: 0.008em !important;
  text-align: left !important;

}

.reporterName {
  font-family: Work Sans !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 19px !important;
  letter-spacing: 0.005em !important;
  text-align: left !important;

}


.Mui-selected {
  background-color: #CC0000 !important;
}

.css-5xe99f-MuiLinearProgress-bar1 {
  background-color: #CC0000 !important;

}

.dropdown-item {
  background-color: #CC0000 !important;
  color: #FFF !important;
  width: 180px;
}

.MuiList-root.MuiList-padding.MuiMenu-list {
  background-color: #CC0000 !important;
}

.trn-1:hover {
  transform: scale(0.8);
  transition: transform 150ms ease-in-out;
}

.trn-2:hover {
  transform: scale(1.2);
  transition: transform 150ms ease-in-out;
}

.trn-3:hover {
  transform: scale(0.9);
  transition: transform 150ms ease-in-out;
}

.contact-input {
  width: 30rem;
  border: 2px solid #BCBCBC;
  margin-bottom: 1rem;
}

.contact-input::placeholder {
  padding-left: 10px;
}

.contact-us-button {
  color: #fff;
  background-color: #CC0000;
  width: 13rem;
  text-align: center;
  border-radius: 5px;
  height: 3rem;
  padding-top: 0.8rem !important;
  margin-left: 8rem !important;
  cursor: pointer;
}

.contact-us-button:hover {
  color: #CC0000;
  background-color: #FFF;
  border: 1px solid #CC0000;
}

.mHide {
  display: block;
}

.bannerHeading1 {
  font-family: Oswald !important;
  font-size: 32px !important;
}

.bannerHeading2 {
  font-family: Oswald !important;
  font-size: 20px !important;
}
.bannerHeading3 {
  font-family: Oswald !important;
  font-size: 20px !important;
}
.bannerHeading4 {
  font-family: Oswald !important;
  font-size: 26px !important;
}


/* Mobile View */
@media screen and (max-width: 767px) {
  .mHide {
    display: none;
  }
  .contact-input{
    width: 100%;
  }
  .contact-us-button{
    margin-left: 3.7rem !important;
  }

  .bannerHeading1{
   font-size: 20px !important;
  }
  .h1{
   font-size: 20px !important;
  }
  .bannerHeading2{
   font-size: 16px !important;
  }
  .bannerHeading3{
   font-size: 16px !important;
  }
  .bannerHeading4{
   font-size: 20px !important;
  }
}