

.heading-title{
    color: #CC0000 !important;
    font-size: 30px !important;
    font-weight: 600 !important;
    line-height: 36px !important;
    text-align: left !important;
    font-family: Oswald !important;
 

}
.heading-subtitle h6 {
    color: #F65050;
    font-size: 20px;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
    font-family: Oswald;

}

.categoryAll {
    background-color: crimson;
    padding: 5px 10px 5px 10px;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
}

.categoryWorld {
    background-color: #24804f !important;
    padding: 5px 10px 5px 10px;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
}

.categoryLifestyle {
    background-color: #00d5ff;
    padding: 5px 10px 5px 10px;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
}

.categoryTechnology {
    background-color: #c05332;
    padding: 5px 10px 5px 10px;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
}


.category {
    padding: 5px 10px 5px 10px;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
}

 .category:nth-child(1) {
    background-color: crimson;
}

 .category:nth-child(2) {
    background-color: #24804f;
}

 .category:nth-child(3) {
    background-color: #00d5ff;
}

 .category:nth-child(4) {
    background-color: #c05332;
}

 .category {
    background-color: #a38f2b;
}
 .category:nth-child(6) {
    background-color: #a14dba;
}
 .category:nth-child(7) {
    background-color: #4c947f;
}
 .category:nth-child(8) {
    background-color: #8b2c09;
}



